import { vendorConstant } from "./../constants";
import axios from "axios";
import { sendMessage } from "./../../firebase";
import { ToastIcon, toast } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import { BACKEND_URL } from "../../constants";
import { emitUserStateChange } from "../../utils";
import { getLoggedInVendor } from "./vendorAuth.action";
import globalHookData from "../../utils/globalHookData";
import { reGetUserDetails2 } from "./user.action";

export const CreateServiceByVendor = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.CREATE_SERVICE_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.post(`${BACKEND_URL}/v1/api/service/create`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "", //the token is a variable which holds the token
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch({
        type: vendorConstant.CREATE_SERVICE_SUCCESS,
        payload: "Service has been created",
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.CREATE_SERVICE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const ChangePasswordByVendor = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.CHANGE_PASSWORD_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.post(`${BACKEND_URL}/v1/api/auth/change/password`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: vendorConstant.CHANGE_PASSWORD_SUCCESS,
        payload: "Password has been changed",
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.CHANGE_PASSWORD_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetMyService = (body, page, isAll = false) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_MY_SERVICE_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const result = await axios.get(
        `${BACKEND_URL}/v1/api/service?vendor=${body}&page=${page}${
          !isAll ? "&limit=9&deleteStatus=NULL&status=Accepted" : ""
        }`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_MY_SERVICE_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_MY_SERVICE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetMyUnviewedStatusUpdateServices = (vendorId) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_UNVIEWED_ALL_SERVICES_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const result = await axios.get(
        `${BACKEND_URL}/v1/api/service?vendor=${vendorId}&page=1&limit=5000&isStatusUpdateViewed=false`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_UNVIEWED_ALL_SERVICES_SUCCESS,
        payload: data?.results,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_UNVIEWED_ALL_SERVICES_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const ChangeUnviewdStatusToView = (ids, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.PATCH_UNVIEW_STATUS_TO_VIEW_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.patch(
        `${BACKEND_URL}/v1/api/service/view-status-update`,
        { ids },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: vendorConstant.PATCH_UNVIEW_STATUS_TO_VIEW_SUCCESS,
      });

      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: vendorConstant.PATCH_UNVIEW_STATUS_TO_VIEW_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const DeleteMyService = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.DELETE_MY_SERVICE_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.delete(`${BACKEND_URL}/v1/api/service/${body}`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });

      dispatch(GetMyService(globalHookData.useUser?.id, 1));
      dispatch({
        type: vendorConstant.DELETE_MY_SERVICE_SUCCESS,
        payload: "Service has been deleted",
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.DELETE_MY_SERVICE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const AddPaymentCard = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.ADD_CARD_REQUEST });
    try {
      console.log("trying card create");
      console.log("card token:", body);
      const token = sessionStorage.getItem("vendorToken");
      const res = await axios.post(`${BACKEND_URL}/v1/api/card`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      // sessionStorage.setItem("vendor", JSON.stringify(res.data));
      // dispatch(GetPaymentCard());
      dispatch({
        type: vendorConstant.ADD_CARD_SUCCESS,
        payload: "Card has been saved",
      });

      dispatch(GetPaymentCard());
    } catch (error) {
      console.log(error);
      dispatch({
        type: vendorConstant.ADD_CARD_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetPaymentCard = () => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_CARD_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const result = await axios.get(`${BACKEND_URL}/v1/api/card/logged/user`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_CARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_CARD_FAILURE,
        payload: { err: "" },
      });

      toast.error(error.response.data.errors[0].message);
    }
  };
};
export const MarkCardAsDefaultPaymentMethod = (id) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const result = await axios.patch(
        `${BACKEND_URL}/v1/api/card/logged/user/${id}`,
        { defaultPaymentId: id },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      // const { data } = result;
      // sessionStorage.setItem("vendor", JSON.stringify(data));
      dispatch(GetPaymentCard());
      dispatch({
        type: vendorConstant.MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_SUCCESS,
        // payload: data,
        payload: { message: "Card has been marked as default payment method" },
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.MARK_CARD_AS_DEFAULT_PAYMENT_METHOD_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const DeletePaymentCard = (id) => {
  // console.log("ID", id);
  return async (dispatch) => {
    dispatch({ type: vendorConstant.DELETE_CARD_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const res = await axios.delete(
        `${BACKEND_URL}/v1/api/card/logged/user/${id}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      // console.log("latest User Data", res.data);
      // sessionStorage.setItem("vendor", JSON.stringify(res.data));

      dispatch(GetPaymentCard());
      dispatch({
        type: vendorConstant.DELETE_CARD_SUCCESS,
        payload: { message: "Card has been deleted", data: [] },
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.DELETE_CARD_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const CreateVendorVerfication = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.CREATE_VERIFICATION_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.post(`${BACKEND_URL}/v1/api/verification`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: vendorConstant.CREATE_VERIFICATION_SUCCESS,
        payload: "Verification has been added",
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.CREATE_VERIFICATION_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const EditUserProfile = (body, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.EDIT_USER_PROFILE_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const result = await axios.patch(
        `${BACKEND_URL}/v1/api/auth/update/user`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.EDIT_USER_PROFILE_SUCCESS,
        payload: "",
      });
      if (data) {
        // sessionStorage.setItem("vendor", JSON.stringify(data));
      }
      emitUserStateChange();
      onSuccess && onSuccess();
      toast.success("Profile has been updated");
    } catch (error) {
      dispatch({
        type: vendorConstant.EDIT_USER_PROFILE_FAILURE,
        payload: { err: "" },
      });

      error.response.data.errors[0].message &&
        toast.error(error.response.data.errors[0].message);
    }
  };
};

export const GetServiceByTypeEvent = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_SERVICE_BY_TYPE_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      let result;
      if (body) {
        result = await axios.get(
          `${BACKEND_URL}/v1/api/service?type=event&page=${body}&limit=16`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      } else {
        result = await axios.get(
          `${BACKEND_URL}/v1/api/service?type=event&limit=10`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      }
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_SERVICE_BY_TYPE_EVENT_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_SERVICE_BY_TYPE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetServiceByTypeProduct = (body) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_SERVICE_BY_TYPE_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      let result;
      if (body) {
        result = await axios.get(
          `${BACKEND_URL}/v1/api/service?type=product&page=${body}&limit=16`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      } else {
        result = await axios.get(
          `${BACKEND_URL}/v1/api/service?type=product&limit=10`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      }
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_SERVICE_BY_TYPE_PRODUCT_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_SERVICE_BY_TYPE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetService = (body, city) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_SERVICE_REQUEST });
    try {
      const token = sessionStorage.getItem("userToken");
      let result;
      if (city) {
        result = await axios.get(
          `${BACKEND_URL}/v1/api/service?${body.heading}=${body.value}&serviceArea=${city}&status=Accepted`,
          body,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      } else {
        result = await axios.get(
          `${BACKEND_URL}/v1/api/service?${body.heading}=${body.value}&status=Accepted`,
          body,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      }
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_SERVICE_SUCCESS,
        payload: {
          results: data.results,
          page: data.page,
          totalPages: data.totalPages,
        },
      });
      // if (data) {
      //   sessionStorage.setItem("user", JSON.stringify(data));
      // }
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_SERVICE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetSingleService = (body, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_SINGLE_SERVICE_REQUEST });
    try {
      const token =
        sessionStorage.getItem("vendorToken") ??
        sessionStorage.getItem("userToken");
      const result = await axios.get(`${BACKEND_URL}/v1/api/service/${body}`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_SINGLE_SERVICE_SUCCESS,
        payload: data,
      });

      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_SINGLE_SERVICE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const AddToFavorite = (body, isRemoving) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.ADD_TO_FAVOURITE_REQUEST });
    try {
      let result = { id: body };
      const token =
        sessionStorage.getItem("vendorToken") ??
        sessionStorage.getItem("userToken");
      await axios.post(
        `${BACKEND_URL}/v1/api/service/favourite/manage`,
        result,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: vendorConstant.ADD_TO_FAVOURITE_SUCCESS,
        payload: isRemoving ? "Removed from favourite!" : "Added to favourite!",
      });
      // dispatch(GetSingleService(body));
    } catch (error) {
      console.log(error);
      dispatch({
        type: vendorConstant.ADD_TO_FAVOURITE_FAILURE,
        payload: { err: error.response?.data?.errors[0]?.message },
      });
    }
  };
};

export const AddToLike = (body, isRemoving) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.ADD_TO_LIKE_REQUEST });
    try {
      let result = { id: body };
      const token =
        sessionStorage.getItem("vendorToken") ??
        sessionStorage.getItem("userToken");
      await axios.post(`${BACKEND_URL}/v1/api/service/like/manage`, result, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: vendorConstant.ADD_TO_LIKE_SUCCESS,
        payload: isRemoving ? "Unliked" : "Thanks for liking.",
      });
      // dispatch(GetSingleService(body));
    } catch (error) {
      console.log(error);
      dispatch({
        type: vendorConstant.ADD_TO_LIKE_FAILURE,
        payload: { err: error.response?.data?.errors[0]?.message },
      });
    }
  };
};

export const GetMyFavortServices = (page) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_FAVOURITE_SERVICES_REQUEST });
    try {
      const token = sessionStorage.getItem("userToken");
      const result = await axios.get(
        `${BACKEND_URL}/v1/api/service/favourite/manage?limit=9&page=${page}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_FAVOURITE_SERVICES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_FAVOURITE_SERVICES_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetMemberShipSubscription = () => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_MEMBERSHIP_SUBSCRIPTION_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const result = await axios.get(`${BACKEND_URL}/v1/api/subscription`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      const { data } = result;
      const order = ["Bronze", "Silver", "Gold", "Platinum"];

      const sortedResults = data.sort((a, b) => {
        const orderA = order.indexOf(a.name);
        const orderB = order.indexOf(b.name);

        return orderA - orderB;
      });
      dispatch({
        type: vendorConstant.GET_MEMBERSHIP_SUBSCRIPTION_SUCCESS,
        payload: sortedResults,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_MEMBERSHIP_SUBSCRIPTION_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const AddMemberShipSubscription = (body, onSuccess, onError) => {
  console.log(body);
  return async (dispatch) => {
    dispatch({ type: vendorConstant.ADD_MEMBERSHIP_SUBSCRIPTION_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const { data } = await axios.post(
        `${BACKEND_URL}/v1/api/subscription`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      // const { data } = result;
      // sessionStorage.setItem("vendor", JSON.stringify(data));
      dispatch({
        type: vendorConstant.ADD_MEMBERSHIP_SUBSCRIPTION_SUCCESS,
        payload: "Membership has been subscribed",
      });
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch({
        type: vendorConstant.ADD_MEMBERSHIP_SUBSCRIPTION_FAILURE,
        payload: { err: onError ? "" : error.response.data.errors[0].message },
      });

      onError && onError(error);
    }
  };
};

export const UpdateSingleService = (body, id, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.UPDATE_SINGLE_SERVICE_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.patch(`${BACKEND_URL}/v1/api/service/${id}`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: vendorConstant.UPDATE_SINGLE_SERVICE_SUCCESS,
        payload: "Edit request has been sent",
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: vendorConstant.UPDATE_SINGLE_SERVICE_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetAllCustomerRequest = (vendorId, page = 1, limit) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_ALL_CUSTOMER_REQUESTS_REQUEST });
    try {
      const token =
        sessionStorage.getItem("vendorToken") ??
        sessionStorage.getItem("userToken");
      const result = await axios.get(
        `${BACKEND_URL}/v1/api/service/customer/message/request/for/vendor/${vendorId}?page=${page}&limit=${limit}&${[
          "pending",
          "accepted",
          "rejected",
        ]
          .map((item, index) => `status[${index}]=${item}`)
          .join("&")}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_ALL_CUSTOMER_REQUESTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_ALL_CUSTOMER_REQUESTS_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetAllCustomerCancelledRequest = (vendorId, page = 1, limit) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.GET_ALL_CUSTOMER_CANCELLED_REQUESTS_REQUEST,
    });
    try {
      const token =
        sessionStorage.getItem("vendorToken") ??
        sessionStorage.getItem("userToken");
      const result = await axios.get(
        `${BACKEND_URL}/v1/api/service/customer/message/request/for/vendor/${vendorId}?page=${page}&limit=${limit}&${[
          "cancelled",
        ]
          .map((item, index) => `status[${index}]=${item}`)
          .join("&")}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_ALL_CUSTOMER_CANCELLED_REQUESTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_ALL_CUSTOMER_CANCELLED_REQUESTS_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetAllCustomerCompletedRequest = (vendorId, page = 1, limit) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.GET_ALL_CUSTOMER_COMPLETED_REQUESTS_REQUEST,
    });
    try {
      const token =
        sessionStorage.getItem("vendorToken") ??
        sessionStorage.getItem("userToken");
      const result = await axios.get(
        `${BACKEND_URL}/v1/api/service/customer/message/request/for/vendor/${vendorId}?page=${page}&limit=${limit}&${[
          "completed",
        ]
          .map((item, index) => `status[${index}]=${item}`)
          .join("&")}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_ALL_CUSTOMER_COMPLETED_REQUESTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_ALL_CUSTOMER_COMPLETED_REQUESTS_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const UpdateCustomerRequest = (id, body, vendorId, onSuccess) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.UPDATE_STATUS_CUSTOMER_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.patch(
        `${BACKEND_URL}/v1/api/service/customer/message/request/for/vendor/${id}`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: vendorConstant.UPDATE_STATUS_CUSTOMER_SUCCESS,
        payload: "Status has been updated",
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: vendorConstant.UPDATE_STATUS_CUSTOMER_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetUserForChat = (vendorId) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_USER_FOR_CHAT_REQUEST });
    try {
      const token =
        sessionStorage.getItem("vendorToken") ??
        sessionStorage.getItem("userToken");
      const result = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/list/user/for/chat/${vendorId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      // console.log("RESPONSE FOR API",data);
      dispatch({
        type: vendorConstant.GET_USER_FOR_CHAT_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_USER_FOR_CHAT_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const GetVendorForChat = (userId) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_VENDOR_FOR_CHAT_REQUEST });
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("userToken");
      const result = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/list/vendor/for/chat/${userId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_VENDOR_FOR_CHAT_SUCCESS,
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_VENDOR_FOR_CHAT_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const CreateChatConnectionForVendor = (body, onSuccess) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.CREATE_CHAT_CONNECTION_REQUEST,
    });
    try {
      const token =
        sessionStorage.getItem("vendorToken") ??
        sessionStorage.getItem("userToken");
      let result = await axios.post(
        `${BACKEND_URL}/v1/api/service/create/customer/vendor/chat/connection`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      onSuccess && onSuccess({ conversationId: data.id });
      dispatch({
        type: vendorConstant.CREATE_CHAT_CONNECTION_SUCCESS,
        payload: {
          results: data,
        },
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.CREATE_CHAT_CONNECTION_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const CreateChatConnectionForUser = (body) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.CREATE_CHAT_CONNECTION_REQUEST,
    });
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("userToken");
      let result = await axios.post(
        `${BACKEND_URL}/v1/api/service/create/customer/vendor/chat/connection`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.CREATE_CHAT_CONNECTION_SUCCESS,
        payload: {
          results: data,
        },
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.CREATE_CHAT_CONNECTION_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};
export const addMessage = (body, onSuccess) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.ADD_MESSAGE_REQUEST,
    });
    try {
      await sendMessage(body);
      // console.log("Message sent successfully, calling on success");
      dispatch({
        type: vendorConstant.ADD_MESSAGE_SUCCESS,
      });
      onSuccess && onSuccess();
      // If the message is successfully added, resolve the promise.
      return Promise.resolve();
    } catch (error) {
      dispatch({
        type: vendorConstant.ADD_MESSAGE_FAILURE,
        payload: { err: error.response.data.message },
      });
      // If there's an error, reject the promise.
      return Promise.reject(error);
    }
  };
};

export const getUserInfo = (body) => {
  return async (dispatch) => {
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      let result = await axios.get(`${BACKEND_URL}/v1/api/auth/${body}`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      const { data } = result;
      console.log("UserInfo", data);
      dispatch({
        type: vendorConstant.GET_USER_INFO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_USER_INFO_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const getTotalFavourite = () => {
  return async (dispatch) => {
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      let result = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/total/favourites`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_TOTAL_FAVOURITE_SUCCESS,
        payload: data.totalLikes,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_TOTAL_FAVOURITE_FAILURE,
        payload: { err: error?.response?.data?.message },
      });
    }
  };
};

export const getTotalLike = () => {
  return async (dispatch) => {
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      let result = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/total/like`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_TOTAL_LIKE_SUCCESS,
        payload: data.totalLikes,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_TOTAL_LIKE_FAILURE,
        payload: { err: error?.response?.data?.message },
      });
    }
  };
};

export const getTotalNumberOfUnviewedServicesUpdate = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: vendorConstant.GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_REQUEST,
      });
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      let result = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/total/status-update-unviewed`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_SUCCESS,
        payload: data.numberOfUnviewedServices,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_TOTAL_NUMBER_OF_UNVIEWED_SERVICE_UPDATE_FAILURE,
        payload: { err: error?.response?.data?.message },
      });
    }
  };
};

export const getTotalViews = () => {
  return async (dispatch) => {
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      let result = await axios.get(
        `${BACKEND_URL}/v1/api/service/get/total/views`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_TOTAL_VIEWS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_TOTAL_VIEWS_FAILURE,
        payload: { err: error.response?.data?.message },
      });
    }
  };
};

export const addNewView = (id) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.ADD_NEW_VIEW_REQUEST });
    try {
      let result = await axios.patch(
        `${BACKEND_URL}/v1/api/service/add/new/views/${id}`
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.ADD_NEW_VIEW_SUCCESS,
        payload: data[0].totalViews,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.ADD_NEW_VIEW_FAILURE,
        payload: { err: error.response?.data?.message },
      });
    }
  };
};

export const GetAllAdmin = () => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.GET_ALL_ADMIN_REQUEST });
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("userToken");
      const result = await axios.get(`${BACKEND_URL}/v1/api/admin`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_ALL_ADMIN_SUCCESS,
        payload: data.results,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_ALL_ADMIN_FAILURE,
        payload: { err: error.response.data.errors[0].message },
      });
    }
  };
};

export const getAdminInfo = (body) => {
  return async (dispatch) => {
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      let result = await axios.get(`${BACKEND_URL}/v1/api/admin/${body}`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_USER_INFO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_USER_INFO_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const getAllChatIdsVendor = () => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.GET_ALL_CHATS_IDS_VENDOR_REQUEST,
    });
    try {
      const token =
        sessionStorage.getItem("userToken") ??
        sessionStorage.getItem("vendorToken");
      let result = await axios.get(
        `${BACKEND_URL}/v1/api/service/create/customer/vendor/chat/connection`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const { data } = result;
      dispatch({
        type: vendorConstant.GET_ALL_CHATS_IDS_VENDOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: vendorConstant.GET_ALL_CHATS_IDS_VENDOR_FAILURE,
        payload: { err: error.response.data.message },
      });
    }
  };
};

export const cancelVendorMembership = (body = {}, onSuccess) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.CANCEL_MEMBERSHIP_VENDOR_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.patch(
        `${BACKEND_URL}/v1/api/subscription/cancel/subscription`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      dispatch({
        type: vendorConstant.CANCEL_MEMBERSHIP_VENDOR_SUCCESS,
      });
      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: vendorConstant.CANCEL_MEMBERSHIP_VENDOR_FAILURE,
      });
      toast.error(error.response?.data?.errors[0]?.message);
    }
  };
};

export const reSubscribeVendorMembership = (onSuccess) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.RESUBSCRIBE_MEMBERSHIP_VENDOR_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.patch(
        `${BACKEND_URL}/v1/api/subscription/resubscribe/subscription`,
        {},
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      toast.success("Re-subscribed successfully!");

      dispatch({
        type: vendorConstant.RESUBSCRIBE_MEMBERSHIP_VENDOR_SUCCESS,
      });

      onSuccess && onSuccess();
    } catch (error) {
      dispatch({
        type: vendorConstant.RESUBSCRIBE_MEMBERSHIP_VENDOR_FAILURE,
      });
      toast.error(error.response?.data?.errors[0]?.message);
    }
  };
};

export const updateVendorMembership = (body, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.UPDATE_MEMBERSHIP_VENDOR_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const { data } = await axios.patch(
        `${BACKEND_URL}/v1/api/subscription`,
        body,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      // sessionStorage.setItem("vendor", JSON.stringify(data));
      dispatch({
        type: vendorConstant.UPDATE_MEMBERSHIP_VENDOR_SUCCESS,
        payload: "Updated successfully!",
      });
      onSuccess && onSuccess();
    } catch (error) {
      onError && onError(error);
      dispatch({
        type: vendorConstant.UPDATE_MEMBERSHIP_VENDOR_FAILURE,
        payload: {
          err: "",
        },
      });
      !onError && toast.error(error.response?.data?.errors[0]?.message);
    }
  };
};

export const getAllServicesOfSingleVendor = (id, page, onError) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.GET_ALL_SERVICES_OF_SINGLE_VENDOR_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/service?vendor=${id}&page=${page}&limit=16`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({
        type: vendorConstant.GET_ALL_SERVICES_OF_SINGLE_VENDOR_SUCCESS,
        payload: {
          services: data?.results ?? [],
          totalPages: data?.totalPages ?? 1,
        },
      });
    } catch (error) {
      onError();
      dispatch({
        type: vendorConstant.GET_ALL_SERVICES_OF_SINGLE_VENDOR_FAILURE,
      });
      toast.error(error.response?.data?.errors[0]?.message);
    }
  };
};

export const getVendorShopServiceByVendorName = (
  search,
  page,
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.GET_ALL_SERVICES_OF_SINGLE_VENDOR_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/auth/get/vendor/${search}?page=${page}&limit=16`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      onSuccess(data);
    } catch (error) {
      onError();
      dispatch({
        type: vendorConstant.GET_ALL_SERVICES_OF_SINGLE_VENDOR_FAILURE,
      });
      toast.error(error.response?.data?.errors[0]?.message);
    }
  };
};

export const getCouponDetails = (name, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: vendorConstant.GET_COUPON_DETAILS_REQUEST,
    });
    try {
      const token = sessionStorage.getItem("vendorToken");
      const { data } = await axios.get(
        `${BACKEND_URL}/v1/api/coupon/query?name=${name}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      dispatch({
        type: vendorConstant.GET_COUPON_DETAILS_SUCCESS,
      });

      onSuccess && onSuccess(data);
    } catch (error) {
      onError && onError();
      dispatch({
        type: vendorConstant.GET_COUPON_DETAILS_FAILURE,
      });
      toast.error(error.response?.data?.errors[0]?.message);
    }
  };
};

export const AddPaymentCardBeforeSubscription = (body, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.ADD_CARD_BEFORE_SUBSCRIPTION_REQUEST });
    try {
      const token = sessionStorage.getItem("vendorToken");
      await axios.post(`${BACKEND_URL}/v1/api/card`, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      dispatch({
        type: vendorConstant.ADD_CARD_BEFORE_SUBSCRIPTION_SUCCESS,
      });

      onSuccess && onSuccess();
    } catch (error) {
      onError && onError();
      console.log(error);
      dispatch({
        type: vendorConstant.ADD_CARD_BEFORE_SUBSCRIPTION_FAILURE,
        payload: { err: error.response?.data?.errors[0]?.message },
      });
    }
  };
};

export const resetVendorReducer = () => ({
  type: vendorConstant.RESET_REDUCER,
});

export const addUserToEmailList = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("vendorToken");
      dispatch({ type: vendorConstant.ADD_TO_EMAIL_LIST_REQUEST });
      await axios.patch(
        `${BACKEND_URL}/v1/api/email-list/toggle`,
        {},
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      dispatch({ type: vendorConstant.ADD_TO_EMAIL_LIST_SUCCESS });

      onSuccess && onSuccess();
    } catch (error) {
      onError && onError();
      dispatch({ type: vendorConstant.ADD_TO_EMAIL_LIST_FAILURE });
    }
  };
};
export const removeUserFromEmailList = (onSuccess, onError) => {
  return async (dispatch) => {
    const email = JSON.parse(sessionStorage.getItem("vendor")).email;
    try {
      dispatch({ type: vendorConstant.REMOVE_FROM_EMAIL_LIST_REQUEST });
      await axios.delete(`${BACKEND_URL}/v1/api/email-list/${email}`);
      dispatch({ type: vendorConstant.REMOVE_FROM_EMAIL_LIST_SUCCESS });

      onSuccess && onSuccess();
    } catch (error) {
      onError && onError();
      dispatch({ type: vendorConstant.REMOVE_FROM_EMAIL_LIST_FAILURE });
    }
  };
};

export const clearChatConnection = () => {
  return async (dispatch) => {
    dispatch({ type: vendorConstant.CLEAR_CHAT_CONNECTION });
  };
};
